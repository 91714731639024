/** @jsx jsx */
import { jsx, Styled, Container } from "theme-ui"
import Layout from "../components/layout"

const Index = () => (
  <Layout>
    <Container sx={{ my: 6, textAlign: `center` }}>
      <Styled.p>TOOLS TO BE ADDED!</Styled.p>
    </Container>
  </Layout>
)

export default Index
